import { Col, Pagination, Row, Spin } from "antd";
import ProgressMatchCard from "Common/ProgressMatchCard";
import React from "react";
import styles from "./Progress.module.scss";
import errImg from "../../images/bat.png";
import { useOutletContext } from "react-router-dom";
import { useState, useEffect } from "react";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";
import { useToasts } from "react-toast-notifications";

const FutureTournaments = () => {
  const [newPage, setNewPage] = useState(1);
  const { addToast } = useToasts();

  const { competitionData } = useOutletContext();
  const {
    // competitionArr,
    curTab,
    // fetchingCompetititon,
    // searchLoading,
    onchangecomponent,
    searchValue,
  } = competitionData;

  const {
    callAPI: fetchCompetitions,
    response: fetchResponseCompetition,
    error: errCompetition,
    loading: fetchingCompetititon,
  } = useAxios({
    url: serviceUrl.getAllCompetitions,
    method: "get",
  });
  const {
    callAPI: searchAPI,
    response: searchRes,
    error: searchErr,
    loading: searchLoading,
  } = useAxios({
    url: serviceUrl.serachCMS,
    method: "get",
  });

  useEffect(() => {
    searchValue === "" &&
      curTab === "future" &&
      fetchCompetitions(
        `?page=${newPage}&per_page=${12}&include_relation=false&status=scheduled&user_type=scorer&query=competition_logo,sponsor_logo`,
        true
      );
  }, [newPage, searchValue]);

  useEffect(() => {
    searchValue &&
      curTab === "future" &&
      searchAPI(
        `?name=${searchValue}&page=1&per_page=10&status=scheduled&query=competition_logo,sponsor_logo`,
        true
      );
  }, [searchValue]);

  useEffect(() => {
    if (errCompetition) {
      addToast(errCompetition?.message || "Something went wrong", {
        appearance: "error",
      });
    }
  }, [addToast, errCompetition]);

  const competitionArr = searchRes
    ? searchRes?.data
    : fetchResponseCompetition?.data || [];

  const onChange = (page) => {
    setNewPage(page);
  };

  return (
    <div style={{ marginTop: "-5px" }}>
      <div className={styles.CsaCardsWrapper}>
        {fetchingCompetititon || searchLoading ? (
          <div className="w-100 py-5 my-5 d-flex align-items-center justify-content-center">
            <Spin size="large" />
          </div>
        ) : competitionArr.length === 0 ? (
          <div className={styles.errContainer}>
            <div className={styles.errImgContainer}>
              <img src={errImg} className={styles.errImg} />
              <p className={styles.errText}>
                Sorry ...There are no tournament scheduled.
              </p>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
              gap: "20px",
            }}
          >
            {competitionArr.map((data) => {
              return (
                data.status === "scheduled" && (
                  <ProgressMatchCard
                    onchangecomponent={onchangecomponent}
                    matchData={data}
                    key={data?.id}
                  />
                )
              );
            })}
          </div>
        )}
      </div>
      <div className=" d-flex w-100 justify-content-end mt-5">
        <Pagination
          // current={''}
          onChange={onChange}
          current={fetchResponseCompetition?.pagination?.page}
          total={fetchResponseCompetition?.pagination?.total}
          size={"small"}
        />
      </div>
    </div>
  );
};

export default FutureTournaments;
