import React, { useEffect } from "react";
import { Col, Row, Table } from "antd";
import ExpandTable from "Common/ExpandTable";
import styles from "./Schedule.module.scss";
import ExpandList from "Common/ScheduleExpandList";

export default function ScheduleTable({ ...props }) {
  const {
    onchangecomponent,
    matchId,
    matchData,
    data
  } = props;

  return (
    <>
      <div className={styles.TournamentsScoreCard}>
        <div className="">
          <ExpandList
            //dataSource={matchDetailData}
            onchangecomponent={onchangecomponent}
            matchData={matchData}
            matchId={matchId}
            data={data}
          />
        </div>
        <div className="my-4"></div>
      </div>
    </>
  );
}
