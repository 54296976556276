import styles from "./CompetitionLiveCards.module.scss";
import React, { useState, useEffect } from "react";
import { Card, Row, Col, AutoComplete, Input, Spin, Pagination } from "antd";
import ScoreCard from "Common/ScoreCard";
import { serviceUrl } from "services/serviceUrl";
import useAxios from "./../../utils/axios";
import moment from "moment";
import errImg from "../../images/bat.png";
import { useLocation, useOutletContext } from "react-router-dom";
import {
  collection,
  getCountFromServer,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  startAt,
  Firestore,
  onSnapshot,
} from "firebase/firestore";
import firebaseDb from "firebase";

function CompetitionLiveCards({ hideTitle, ...props }) {
  const { onchangecomponent, activeTab, matchId } = props;

  const [loadPage, setLoadPage] = useState(false);
  const [fResponse, setResponse] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(12);

  const location = useLocation();

  const tournamentId = matchId
  console.log(tournamentId, "tid");

  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentPosts = fResponse?.slice(indexOfFirstPost, indexOfLastPost);
  const collectionName = process.env.REACT_APP_F_MAIN_COLLECTION;

  const excludedStatuses = ["Finished"];

  const colRef = collection(firebaseDb, collectionName);
  const q = query(
    colRef,
    where("tournamentId", "==", tournamentId?.toString()),
    where("matchStatus", "not-in", excludedStatuses)
  )

  useEffect(() => {
    setLoadPage(true);

    const unsubscribeMain = onSnapshot(q, (snapshot) => {
      const promises = snapshot.docs.map(async (doc) => {
        const inningsColRef = collection(doc.ref, "innings");
        const inningsQ = query(inningsColRef);

        const unsubscribeInnings = onSnapshot(inningsQ, (inningsSnapshot) => {
          let inningsData = [];

          inningsSnapshot.forEach((inningsDoc) => {
            inningsData.push({
              ...inningsDoc.data(),
              inningsId: inningsDoc.id,
            });
          });

          const matchData = {
            ...doc.data(),
            id: doc.id,
            innings: inningsData,
          };

          setResponse((prevResponse) => {
            const index = prevResponse.findIndex(
              (item) => item.id === matchData.id
            );
            if (index !== -1) {
              const updatedResponse = [...prevResponse];
              updatedResponse[index] = matchData;
              return updatedResponse;
            } else {
              // If the match doesn't exist in state, add it
              return [...prevResponse, matchData];
            }
          });
        });

        return unsubscribeInnings;
      });

      Promise.all(promises).then(() => {
        setLoadPage(false);
      });
    });

    return () => unsubscribeMain();
  }, [activeTab]);

  console.log(fResponse, "ress");

  return (
    <>
      {!hideTitle && (
        <div className={styles.CsaCardsHeader}>
          <p className={styles.heading}>Live Matches</p>
        </div>
      )}
      <>
        <div className={styles.CsaCardsWrapper}>
          {loadPage ? (
            <div className="w-100 py-5 my-5 d-flex align-items-center justify-content-center">
              <Spin size="large" />
            </div>
          ) : currentPosts.length === 0 ? (
            <div className={styles.errContainer}>
              <div className={styles.errImgContainer}>
                <img src={errImg} className={styles.errImg} />
                <p className={styles.errText}>
                  Sorry...There are no live matches at the moment.
                </p>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                gap: "20px",
              }}
            >
              {currentPosts?.map((item) => {
                return (
                  <ScoreCard
                    onchangecomponent={onchangecomponent}
                    scoreData={item}
                    key={item?.id}
                    propsData={props}
                  />
                );
              })}
            </div>
          )}
          <div className=" d-flex w-100 justify-content-end mt-5">
            <Pagination
              onChange={(page) => setCurrentPage(page)}
              current={currentPage}
              total={fResponse?.length}
              pageSize={12}
              size={"small"}
              // onShowSizeChanger={false}
              showSizeChanger={false}
            />
          </div>
        </div>
      </>
    </>
  );
}

export default CompetitionLiveCards;
