import React, { useEffect, useState } from "react";
import styles from "./Standings.module.scss";
import { Table, Row, Col, AutoComplete, Input, Pagination, Spin } from "antd";
import { useToasts } from "react-toast-notifications";
import nologo from "../../images/nologoclub.png";

import useAxios from "./../../utils/axios";
import { serviceUrl } from "services/serviceUrl";
import ExpandTable from "Common/ExpandTable";
import { date } from "yup/lib/locale";

function StandingsTable({ ...props }) {
  const { matchId, standingsData } = props;
  const { addToast } = useToasts();
  const [hideBody, setHideBody] = useState(true);
  const [expandablelistId, setExpandableListId] = React.useState(-1);

  const ExpandableList = (i, eachItem) => {
    setExpandableListId(expandablelistId === i ? -1 : i);
  };

  const openOrCloseAll = () => {
    setHideBody((current) => !current);
  };

  const {
    callAPI: fetchStandingById,
    response: responseStanding,
    error: errStanding,
    loading: fetchingStanding,
  } = useAxios({
    url: serviceUrl.getCompetitionById + `${matchId}/` + `standings`,
    method: "get",
  });
  useEffect(() => {
    // tabNum === "2" &&
    fetchStandingById();
  }, []);

  const standingsArr = responseStanding?.data || [];

  

  return (
    <>
      <div className={styles.contentWrapper}>
        <div className={styles.contentWrapper2}>
          <div className={styles.ExpandListItems}>
            <span style={{ width: "80px" }}>Team</span>
            <span style={{ width: "180px" }}></span>{" "}
            <span style={{ width: "10%", textAlign: "center" }}>Played</span>
            <span style={{ width: "10%", textAlign: "center" }}>Won</span>{" "}
            <span style={{ width: "10%", textAlign: "center" }}>Lost</span>
            <span style={{ width: "10%", textAlign: "center" }}>Draw</span>{" "}
            {/* <span style={{ width: "10%", textAlign: "center" }}>Points</span>
            <span style={{ width: "10%", textAlign: "center" }}>NRR</span> */}
          </div>
          <div style={{ marginTop: "-5px" }}>
            {fetchingStanding ? (
              <div className="w-100 py-5 my-5 d-flex align-items-center justify-content-center">
                <Spin size="large" />
              </div>
            ) : (
              Object.keys(standingsArr).map((key, index) => {
                // 
                return (
                  <div className={styles.ExpandListWrapper}>
                    <div className={styles.ListTitleWrapper}>
                      <div className="expand-table-common-icon">
                        <div
                          key={key}
                          onClick={() => ExpandableList(index + 1)}
                          // onClick={openOrCloseAll} //openOrCloseAll click header button event
                          style={{
                            display: !(expandablelistId === index + 1)
                              ? "none"
                              : "inline-block",
                          }}
                          className="ant-table-row-expand-icon ant-table-row-expand-icon-expanded"
                        />
                        <div
                          onClick={() => ExpandableList(index + 1)}
                          // onClick={openOrCloseAll}
                          style={{
                            display: !(expandablelistId === index + 1)
                              ? "inline-block"
                              : "none",
                          }}
                          className="ant-table-row-expand-icon ant-table-row-expand-icon-collapsed"
                        />
                      </div>
                      <p className={styles.ListTitle}>Pool {key}</p>
                    </div>

                    {expandablelistId === index + 1 ? (
                      <>
                        {standingsArr[key]
                          ?.sort(
                          function(a, b) {
                            let point1 = a.points;
                            let point2 = b.points;
                        
                            let rr1 = a.net_run_rate;
                            let rr2 = b.net_run_rate;
                        
                            let played1 = a.played;
                            let played2 = b.played;
                        
                            if (point1 > point2) return -1;
                            if (point1 < point2) return 1;
                        
                            if (point1 === point2  && played2 > 0 && played1 > 0 &&  rr1 > rr2) return -1;
                            if (point1 === point2  && played2 > 0 && played1 > 0 && rr1 < rr2 ) return 1;
                        
                            if (point1 === point2 && played2 ===0 && played1 >0 && played1 > played2) return -1;
                            if (point1 === point2 && played2 ===0 && played1 >0 && played1 < played2) return 1;
                        
                            // if (sr1 < sr2) return -1;
                            // if (sr1 > sr2) return 1;
                        
                            return 0;
                              // b.points < a.points && b.points - a.points || 
                              // b.points === a.points && b.played >0 && a.played > 0 &&
                              //   b.net_run_rate - a.net_run_rate ||
                              // b.points === a.points && b.played === 0 && a.played >0 && a.played - b.palyed 
                          })
                          ?.map((i) => {
                            // 
                            return (
                              <div className={styles.ExpandList}>
                                <span style={{ width: "80px" }}>
                                  <img
                                    className={styles.Teamimg}
                                    src={i?.team_logo ? i.team_logo : nologo}
                                  />
                                </span>
                                <span style={{ width: "180px" }}>
                                  {i?.team_name}
                                </span>{" "}
                                <span
                                  style={{ width: "10%", textAlign: "center" }}
                                >
                                  {i?.played}
                                </span>
                                <span
                                  style={{ width: "10%", textAlign: "center" }}
                                >
                                  {i?.won}
                                </span>{" "}
                                <span
                                  style={{ width: "10%", textAlign: "center" }}
                                >
                                  {i?.lost}
                                </span>
                                <span
                                  style={{ width: "10%", textAlign: "center" }}
                                >
                                  {i?.draw}
                                </span>{" "}
                                {/* <span
                                  style={{ width: "10%", textAlign: "center" }}
                                >
                                  {i?.points}
                                </span>
                                <span
                                  style={{ width: "10%", textAlign: "center" }}
                                >
                                  {i?.net_run_rate.toFixed(2)}
                                </span> */}
                              </div>
                            );
                          })}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default StandingsTable;

// {/* <Table
//  className="yellow-expand-table"
//   loading={fetchingCompetititon}
//   columns={columns}
//   dataSource={poolwiseStandings}
//   pagination={false}
//   expandable={{rowExpandable :(record) => true,
//   expandedRowRender:(record) => {
//     return(
//       <Table

//       />
//     )
//   }
// }}
//   key={12}
// /> */}
