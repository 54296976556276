import React, { useState } from "react";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { useToasts } from "react-toast-notifications";
import styles from "./CsaImageUpload.module.scss";
import upload from "../../images/upload-img.png";
import Logo from "../../images/CSAyellow.jpg";
const getSrcFromFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.originFileObj);
    reader.onload = () => resolve(reader.result);
  });
};
function CsaImageUpload({
  name,
  fileList,
  onChange,
  action,
  styleH = false,
  type,
  onRemove,
  disabled,
  ...props
}) {
  const { addToast } = useToasts();
  const onPreview = async (file) => {
    const src = file.url || (await getSrcFromFile(file));
    const imgWindow = window.open(src);

    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };

  const fileSize = fileList.size / 1024 / 1024 < 2;
  //

  return (
    <div className={styles.CsaImageUploadWrapper}>
      <ImgCrop
      fillColor="transparent"
        grid
        rotate
        aspect={
          (type && type === "landscape" && 3 / 2) ||
          (type === "banner" && 6 / 1) ||
          (type && type === "streamLogo" && 2 / 1) ||
          (type === "ad-banner" && 19.2 / 1.5) ||
          1 / 1
        }
      >
        <Upload
          beforeUpload={(fileList) => {
            const isLt2M = fileList.size / 1024 / 1024 < 2;
            // const isLt50K = fileList.size > 5000
            if (!isLt2M) {
              addToast("Image must smaller than 2MB!", {
                appearance: "warning",
              });

              return null;
            }
            // if (!isLt50K) {
            //   addToast("Image must larger than 50kB!", { appearance: "warning" });
            //
            //   return null;
            //  }
            else {
              return true;
            }
          }}
          accept=".png, .jpg, .jpeg, .gif"
          action={action}
          listType="picture-card"
          fileList={fileList}
          onChange={onChange}
          onPreview={onPreview}
          name={name}
          showUploadList={true}
          onRemove={onRemove}
          // className="upload-list-inline"
          className={styleH ? styles.uploadH : styles.uploadN}
          //  className={styles.imgStyles}
          {...props}
          disabled={disabled}
        >
          {fileList.length < 1 && (
            <img src={styleH ? Logo : upload} alt="user" />
          )}
        </Upload>
      </ImgCrop>
    </div>
  );
}

export default CsaImageUpload;
