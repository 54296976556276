import Coaches from "../../images/SideNavIcons/Coaches.png";
import StaffMatchOfficials from "../../images/SideNavIcons/StaffMatchOfficials.png";
import Umpires from "../../images/SideNavIcons/Umpires.png";
import Scorers from "../../images/SideNavIcons/Scorers.png";
import MatchReferees from "../../images/SideNavIcons/MatchReferees.png";
import National from "../../images/SideNavIcons/National.png";
import Players from "../../images/SideNavIcons/Players.png";
import Teams from "../../images/SideNavIcons/Teams.png";
import Provincial from "../../images/SideNavIcons/Provincial.png";
import TeamMembers from "../../images/SideNavIcons/TeamMembers.png";
import Members from "../../images/SideNavIcons/Members.png";
import Amateur from "../../images/SideNavIcons/Amateur.png";
import PrimarySchool from "../../images/SideNavIcons/PrimarySchool.png";
import HighSchool from "../../images/SideNavIcons/HighSchool.png";
import Clubs from "../../images/SideNavIcons/Clubs.png";
import AncillaryMembers from "../../images/SideNavIcons/AncillaryMembers.png";
import Facilities from "../../images/SideNavIcons/Facilities.png";
import Fields from "../../images/SideNavIcons/Fields.png";
import Nets from "../../images/SideNavIcons/Nets.png";
import CMS from "../../images/SideNavIcons/CMS.png";
import Reports from "../../images/SideNavIcons/Reports.png";
import LoginAs from "../../images/SideNavIcons/LoginAs.png";
import Hub from "../../images/SideNavIcons/Hub RPC.png";
import minicricket from "../../images/SideNavIcons/minicricket.png";
import Streaming from "../../images/SideNavIcons/streaming.png";
export const NavConfig = (level, role) => {
  const userLevel = localStorage.getItem("csa_level")?.toUpperCase();
  const userLevels = JSON.parse(localStorage.getItem("user_level"))
  const allowLoginAs = userLevels?.includes("CSA")
  const authRole = localStorage.getItem("selRole")?.toUpperCase();
  const roles = JSON.parse(localStorage.getItem("roles"))

  console.log(authRole,"rolee");
  
  const subMemberType = localStorage.getItem("sub_member_type");
  const isSubMemberAdmin =
    authRole === "SUB MEMBERS ADMINISTRATOR" ||
    authRole === "MEMBER PATHWAY STRUCTURE ADMINISTRATOR";

  return [
    authRole === "CSA ADMINISTRATOR" ||
    authRole === "CSA ADMIN ASSISTANT" ||
    authRole === "CSA VIEW ONLY" ||
    authRole === "CSA COACHES MANAGER" ||
    authRole === "CSA UMPIRES MANAGER" ||
    authRole === "MEMBERS ADMINISTRATOR" ||
    authRole === "MEMBERS ADMIN ASSISTANT" ||
    authRole === "MEMBERS VIEW ONLY" ||
    authRole === "MEMBERS COACHES MANAGER" ||
    authRole === "MEMBERS UMPIRES MANAGER" ||
    authRole === "MEMBERS SCORERS MANAGER" ||
    authRole === "CSA MATCH REFEREE MANAGER" ||
    authRole === "SUB MEMBERS ADMINISTRATOR" ||
    authRole === "SUB MEMBERS VIEW ONLY" ||
    authRole === "MEMBER PATHWAY STRUCTURE ADMINISTRATOR" ||
    authRole === "MEMBER PATHWAY STRUCTURE ADMINISTRATOR" ||
    authRole === "MEMBER PATHWAY STRUCTURE ADMINISTRATOR" ||
    authRole === "MEMBER PATHWAY STRUCTURE VIEW ONLY" ||
    authRole === "MEMBER PATHWAY STRUCTURE VIEW ONLY"  ||
    // roles?.includes("members_coaches_manager") ||
    // roles?.includes("members_umpires_manager") ||
    // roles?.includes("members_scorers_manager") ||
    //     roles?.includes("csa_coaches_manager")
   !allowLoginAs && roles?.includes("scorers") ||
   !allowLoginAs &&  roles?.includes("umpires") ||
   !allowLoginAs &&  roles?.includes("coaches")
      ? {
          name: "Staff & Match Officials",
          route: "/staff-list",
          icon: StaffMatchOfficials,

          children: [
            authRole === "CSA ADMINISTRATOR" ||
            authRole === "CSA ADMIN ASSISTANT" ||
            authRole === "CSA VIEW ONLY" ||
            authRole === "MEMBERS ADMINISTRATOR" ||
            authRole === "MEMBERS ADMIN ASSISTANT" ||
            authRole === "MEMBERS VIEW ONLY" ||
            authRole === "SUB MEMBERS ADMINISTRATOR" ||
            authRole === "SUB MEMBERS VIEW ONLY" ||
            authRole === "MEMBER PATHWAY STRUCTURE ADMINISTRATOR" ||
            authRole === "MEMBER PATHWAY STRUCTURE ADMINISTRATOR" ||
            authRole === "MEMBER PATHWAY STRUCTURE ADMINISTRATOR" ||
            authRole === "MEMBER PATHWAY STRUCTURE VIEW ONLY" ||
            authRole === "MEMBER PATHWAY STRUCTURE VIEW ONLY" 
              ? {
                  name: "Staff Members",
                  route: "/staff-list",
                  icon: StaffMatchOfficials,
                }
              : { name: "", route: "" },
            authRole === "CSA ADMINISTRATOR" ||
            authRole === "CSA ADMIN ASSISTANT" ||
            authRole === "CSA VIEW ONLY" ||
            authRole === "CSA COACHES MANAGER" ||
            authRole === "MEMBERS ADMINISTRATOR" ||
            authRole === "MEMBERS ADMIN ASSISTANT" ||
            authRole === "MEMBERS COACHES MANAGER" ||
            authRole === "MEMBERS VIEW ONLY" ||
            authRole === "SUB MEMBERS ADMINISTRATOR" ||
            authRole === "SUB MEMBERS VIEW ONLY" ||
            authRole === "MEMBER PATHWAY STRUCTURE ADMINISTRATOR" ||
            authRole === "MEMBER PATHWAY STRUCTURE ADMINISTRATOR" ||
            authRole === "MEMBER PATHWAY STRUCTURE ADMINISTRATOR" ||
            authRole === "MEMBER PATHWAY STRUCTURE VIEW ONLY" ||
            authRole === "MEMBER PATHWAY STRUCTURE VIEW ONLY" 
            // roles?.includes("members_coaches_manager") 
            //  ||
            // roles?.includes("csa_coaches_manager")
              ? { name: "Coaches", route: "/coach-list", icon: Coaches }
              : { name: "", route: "" },
            authRole === "CSA ADMINISTRATOR" ||
            authRole === "CSA ADMIN ASSISTANT" ||
            authRole === "CSA VIEW ONLY" ||
            authRole === "CSA UMPIRES MANAGER" ||
            authRole === "MEMBERS ADMINISTRATOR" ||
            authRole === "MEMBERS ADMIN ASSISTANT" ||
            authRole === "MEMBERS UMPIRES MANAGER" ||
            authRole === "MEMBERS VIEW ONLY" ||
            !allowLoginAs && roles?.includes("members_umpires_manager") 
            // roles?.includes("csa_umpires_manager")
              ? { name: "Umpires", route: "/umpire-list", icon: Umpires }
              : { name: "", route: "" },
            //Only Member and Submember
            authRole === "MEMBERS ADMINISTRATOR" ||
            authRole === "MEMBERS ADMIN ASSISTANT" ||
            authRole === "MEMBERS SCORERS MANAGER" ||
            authRole === "MEMBERS VIEW ONLY" ||
            authRole === "SUB MEMBERS ADMINISTRATOR" ||
            authRole === "SUB MEMBERS VIEW ONLY" ||
            authRole === "MEMBER PATHWAY STRUCTURE ADMINISTRATOR" ||
            authRole === "MEMBER PATHWAY STRUCTURE ADMINISTRATOR" ||
            authRole === "MEMBER PATHWAY STRUCTURE ADMINISTRATOR" ||
            authRole === "MEMBER PATHWAY STRUCTURE VIEW ONLY" ||
            authRole === "MEMBER PATHWAY STRUCTURE VIEW ONLY" ||
            !allowLoginAs && roles?.includes("members_scorers_manager") ||
            !allowLoginAs && roles?.includes("scorers") ||
            !allowLoginAs &&  roles?.includes("csa_scorers_manager") 
              ? {
                  name: "Scorers",
                  route: "/scorer-list",
                  icon: Scorers,
                }
              : { name: "", route: "" },

            authRole === "CSA ADMINISTRATOR" ||
            authRole === "CSA ADMIN ASSISTANT" ||
            authRole === "CSA VIEW ONLY" ||
            authRole === "CSA MATCH REFEREE MANAGER"
            //  ||
            // roles?.includes("csa_match_referee_manager") 
              ? {
                  name: "Match Referees",
                  route: "/match-referee-list",
                  icon: MatchReferees,
                }
              : { name: "", route: "" },
          ]?.filter((item) => item?.name !== ""),
        }
      : { name: "" },
    //Only CSA
    authRole === "CSA ADMINISTRATOR" ||
    authRole === "CSA ADMIN ASSISTANT" ||
    authRole === "CSA VIEW ONLY"
      ? {
          name: "National",
          route: "/player-list",
          icon: National,
          children: [
            {
              name: "Players",
              route: "/player-list",
              icon: Players,
            },
            {
              name: "Teams",
              route: "/team-list",
              icon: Teams,
            },
          ],
        }
      : { name: "" },

    //Only Members
    authRole === "MEMBERS ADMINISTRATOR" ||
    authRole === "MEMBERS ADMIN ASSISTANT" ||
    authRole === "MEMBERS VIEW ONLY"
      ? {
          name: "Provincial",
          route: "/player-list",
          icon: Provincial,
          children: [
            {
              name: "Players",
              route: "/player-list",
              icon: Players,
            },
            {
              name: "Teams",
              route: "/team-list",
              icon: Teams,
            },
          ],
        }
      : { name: "" },

    //Only Members
    authRole === "MEMBER PATHWAY STRUCTURE ADMINISTRATOR" ||
    authRole === "MEMBER PATHWAY STRUCTURE ADMINISTRATOR" ||
    authRole === "MEMBER PATHWAY STRUCTURE ADMINISTRATOR" ||
    authRole === "MEMBER PATHWAY STRUCTURE VIEW ONLY" ||
    authRole === "SUB MEMBERS ADMINISTRATOR" ||
    authRole === "SUB MEMBERS VIEW ONLY" ||
    authRole === "MEMBER PATHWAY STRUCTURE VIEW ONLY"
      ? {
          name: "Players & Teams",
          route: "/player-list",
          icon: TeamMembers,
          children: [
            {
              name: "Players",
              route: "/player-list",
              icon: Players,
            },
            {
              name: "Teams",
              route: "/team-list",
              icon: Teams,
            },
          ],
        }
      : { name: "" },

    //Only CSA
    authRole === "CSA ADMINISTRATOR" ||
    authRole === "CSA ADMIN ASSISTANT" ||
    authRole === "CSA VIEW ONLY"
      ? { name: "Members", route: "/member-list", icon: Members }
      : { name: "" },
    //Only Members
     userLevel === "MEMBER" && (
    authRole === "MEMBERS ADMINISTRATOR" ||
    authRole === "MEMBERS ADMIN ASSISTANT" ||
    authRole === "MEMBERS PRIMARY SCHOOL COORDINATOR" ||
    authRole === "MEMBERS HIGH SCHOOL COORDINATOR" ||
    authRole === "MEMBER CLUB COORDINATOR" ||
    authRole === "MEMBER ANCILLARY ADMINISTRATOR" ||
    authRole === "MEMBERS VIEW ONLY"
    //  roles?.includes("members_high_school_coordinator") ||
    //  roles?.includes("member_ancillary_administrator") ||
    //  roles?.includes("members_primary_school_coordinator") ||
    //  roles?.includes("member_club_coordinator")
    )
       ? {
          name: "Amateur",
          route: "/primary-school-list",
          icon: Amateur,
          children: [
            authRole === "CSA ADMINISTRATOR" ||
            authRole === "CSA ADMIN ASSISTANT" ||
            authRole === "CSA VIEW ONLY" ||
            authRole === "MEMBERS ADMINISTRATOR" ||
            authRole === "MEMBERS ADMIN ASSISTANT" ||
            authRole === "MEMBERS PRIMARY SCHOOL COORDINATOR" ||
            authRole === "MEMBERS VIEW ONLY"
            // roles?.includes("members_primary_school_coordinator") 
              ? {
                  name: "Primary Schools",
                  route: "/primary-school-list",
                  icon: PrimarySchool,
                }
              : { name: "", route: "" },
            authRole === "CSA ADMINISTRATOR" ||
            authRole === "CSA ADMIN ASSISTANT" ||
            authRole === "CSA VIEW ONLY" ||
            authRole === "MEMBERS ADMINISTRATOR" ||
            authRole === "MEMBERS ADMIN ASSISTANT" ||
            authRole === "MEMBERS HIGH SCHOOL COORDINATOR" ||
            authRole === "MEMBERS VIEW ONLY"
              ? {
                  name: "High Schools",
                  route: "/high-school-list",
                  icon: HighSchool,
                }
              : { name: "", route: "" },
            authRole === "CSA ADMINISTRATOR" ||
            authRole === "CSA ADMIN ASSISTANT" ||
            authRole === "CSA VIEW ONLY" ||
            authRole === "MEMBERS ADMINISTRATOR" ||
            authRole === "MEMBERS ADMIN ASSISTANT" ||
            authRole === "MEMBER CLUB COORDINATOR" ||
            authRole === "MEMBERS VIEW ONLY"
              ? { name: "Club", route: "/club-list", icon: Clubs }
              : { name: "", route: "" },

            authRole === "CSA ADMINISTRATOR" ||
            authRole === "CSA ADMIN ASSISTANT" ||
            authRole === "CSA VIEW ONLY" ||
            authRole === "MEMBERS ADMINISTRATOR" ||
            authRole === "MEMBERS ADMIN ASSISTANT" ||
            // authRole === "MEMBER CLUB COORDINATOR" ||
            authRole === "MEMBERS VIEW ONLY"
              ? { name: "Hub/RPC", route: "/hub-rpc", icon: Hub }
              : { name: "", route: "" },

            authRole === "CSA ADMINISTRATOR" ||
            authRole === "CSA ADMIN ASSISTANT" ||
            authRole === "CSA VIEW ONLY" ||
            authRole === "MEMBERS ADMINISTRATOR" ||
            authRole === "MEMBERS ADMIN ASSISTANT" ||
            // authRole === "MEMBER CLUB COORDINATOR" ||
            authRole === "MEMBERS VIEW ONLY"
              ? {
                  name: "Mini Cricket",
                  route: "/mini-cricket",
                  icon: minicricket,
                }
              : { name: "", route: "" },

            authRole === "CSA ADMINISTRATOR" ||
            authRole === "CSA ADMIN ASSISTANT" ||
            authRole === "CSA VIEW ONLY" ||
            authRole === "MEMBERS ADMINISTRATOR" ||
            authRole === "MEMBERS ADMIN ASSISTANT" ||
            authRole === "MEMBER ANCILLARY ADMINISTRATOR" ||
            authRole === "MEMBERS VIEW ONLY" 
              ? {
                  name: "Ancillary Members",
                  route: "/ancillary-member-list",
                  icon: AncillaryMembers,
                }
              : { name: "", route: "" },
          ]?.filter((item) => item?.name !== ""),
        }
      : { name: "" },
    //Only Members and Submember

    authRole === "MEMBERS ADMINISTRATOR" ||
    authRole === "MEMBERS ADMIN ASSISTANT" ||
    authRole === "MEMBERS VIEW ONLY" ||
    authRole === "SUB MEMBERS ADMINISTRATOR" ||
    authRole === "SUB-MEMBERS ADMINISTRATOR" ||
    authRole === "SUB-MEMBERS ADMINISTRATOR" ||
    authRole === "SUB MEMBERS VIEW ONLY" ||
    authRole === "SUB-MEMBERS VIEW ONLY" ||
    authRole === "MEMBER PATHWAY STRUCTURE ADMINISTRATOR" ||
    authRole === "MEMBER PATHWAY STRUCTURE VIEW ONLY"
      ? {
          name: "Facilities",
          route: "/field-list",
          icon: Facilities,
          children: [
            { name: "Fields", route: "/field-list", icon: Fields },
            { name: "Nets", route: "/net-list", icon: Nets },
          ],
        }
      : { name: "", route: "" },
    //Only Sub Members
    isSubMemberAdmin && subMemberType === "clubs_schools"
      ? {
          name: "Club Administration",
          route: "/club-administration",
          icon: Provincial,
        }
      : { name: "" },
    authRole === "CSA ADMINISTRATOR" ||
    authRole === "CSA ADMIN ASSISTANT" ||
    authRole === "CSA VIEW ONLY" ||
    authRole === "MEMBERS ADMINISTRATOR" ||
    authRole === "MEMBERS ADMIN ASSISTANT" ||
    authRole === "MEMBERS VIEW ONLY"
      ? { name: "CMS", route: "/cms-list", icon: CMS }
      : { name: "", route: "" },
    authRole === "CSA ADMINISTRATOR" ||
    authRole === "CSA ADMIN ASSISTANT" ||
    authRole === "MEMBERS ADMINISTRATOR" ||
    authRole === "MEMBERS ADMIN ASSISTANT" ||
    authRole === "MEMBERS VIEW ONLY" ||
    authRole === "SUB MEMBERS ADMINISTRATOR" ||
    authRole === "SUB-MEMBERS ADMINISTRATOR" ||
    authRole === "SUB-MEMBERS ADMINISTRATOR" ||
    authRole === "SUB MEMBERS VIEW ONLY" ||
    authRole === "SUB-MEMBERS VIEW ONLY" ||
    authRole === "MEMBER PATHWAY STRUCTURE ADMINISTRATOR" ||
    authRole === "MEMBER PATHWAY STRUCTURE VIEW ONLY"
      ? {
          name: "Reports",
          route: "/reports",
          icon: Reports,
          children:
            authRole === "CSA ADMINISTRATOR" ||
            authRole === "CSA ADMIN ASSISTANT"
              ? [
                  { name: "Export", route: "/reports", icon: Reports },
                  { name: "Graphs", route: "/graphs", icon: Reports },
                ]
              : authRole === "MEMBERS ADMINISTRATOR" ||
                authRole === "MEMBERS ADMIN ASSISTANT" ||
                authRole === "MEMBERS VIEW ONLY" ||
                authRole === "SUB MEMBERS ADMINISTRATOR" ||
                authRole === "SUB-MEMBERS ADMINISTRATOR" ||
                authRole === "SUB-MEMBERS ADMINISTRATOR" ||
                authRole === "SUB MEMBERS VIEW ONLY" ||
                authRole === "SUB-MEMBERS VIEW ONLY" ||
                authRole === "MEMBER PATHWAY STRUCTURE ADMINISTRATOR" ||
                authRole === "MEMBER PATHWAY STRUCTURE VIEW ONLY"
              ? [{ name: "Graphs", route: "/graphs", icon: Reports }]
              : { name: "", route: "" },
        }
      : { name: "", route: "" },
      // allowLoginAs
    authRole === "CSA ADMINISTRATOR" ||
    authRole === "CSA ADMIN ASSISTANT" ||
    authRole === "MEMBERS ADMINISTRATOR" ||
    authRole === "MEMBERS ADMIN ASSISTANT" ||
    allowLoginAs && authRole === "SUB MEMBERS ADMINISTRATOR" ||
    allowLoginAs && authRole === "SUB MEMBERS VIEW ONLY" ||
    allowLoginAs && authRole === "MEMBER PATHWAY STRUCTURE ADMINISTRATOR" ||
    authRole === "CSA COACHES MANAGER" ||
    authRole === "CSA UMPIRES MANAGER" ||
    authRole === "CSA MATCH REFEREE MANAGER" ||
    authRole === "MEMBERS COACHES MANAGER" ||
    authRole === "MEMBERS PRIMARY SCHOOL COORDINATOR" ||
    authRole === "MEMBER CLUB COORDINATOR" ||
    authRole === "MEMBERS HIGH SCHOOL COORDINATOR" ||
    authRole === "MEMBER ANCILLARY ADMINISTRATOR" || 
    // authRole === "MEMBER PATHWAY STRUCTURE VIEW ONLY" ||
    // authRole === "MEMBER PATHWAY STRUCTURE VIEW ONLY"
    authRole === "CSA VIEW ONLY" ||
    authRole === "MEMBERS VIEW ONLY" ||
    allowLoginAs
      ? { name: "Login As", route: "/login-as", icon: LoginAs }
      : { name: "", route: "" },
  ]?.filter((item) => item?.name !== "");
};
