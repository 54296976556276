const base_URl_CMS = "https://api-tms.cricketpvcms.co.za/api/v1";
const base_URl_REPORTS = "https://reports-api.cricketpvcms.co.za/api";
const base_Url_Streaming = process.env.REACT_APP_BASE_URL_STREAMING;
//"http://api-dev.csacms.xyz";
// "https://api-dev.cms-backend.ml/api/v1";
// "http://cms-stag.ap-south-1.elasticbeanstalk.com/api/v1/";
export const serviceUrl = {
  ImgeUploadCurrentUser: "/profile-image",
  ImageUploadUrl:
    "/file/upload/node/staff_match_officials/field_profile?_format=json",
  imgUpload: `file/upload/node/staff_match_officials/field_profile?_format=json`,
  fileUpload: `file/upload/node/staff_match_officials/field_level_1_file?_format=json`,
  login: "/user/email-login?_format=json",
  logout: "/user/logout?token=",
  getStaffDetails: "/staff-listing-api?",
  getStaffDetailByID: "/staff-details/",
  setStaff: "/register/staff/create",
  editStaff: "/register/staff",
  getCoachDetails: "/coach-listing-api?",
  getCoachDetailByID: "/coach-details/",
  getCoachDetailByIDNumber: "/coach-details-id/",
  setCoach: "/register/coach/create",
  editCoach: "/register/coach",
  getUmpireDetails: "/umpire-listing-api?",
  getUmpireDetailByID: "/umpire-details/",
  getUmpireDetailByIDNumber: "/umpire-details-id/",
  setUmpire: "/register/umpire/create",
  editUmpire: "/register/umpire",
  getScorerDetails: "/scorer-listing-api?",
  getScorerDetailByID: "/scorer-details/",
  getScorerDetailByIDNumber: "/scorer-details-id/",

  setScorer: "/register/scorers/create",
  editScorer: "/register/scorers",
  getRefereeDetails: "/referee-listing-api?",
  getRefereeDetailByID: "/referee-details/",
  setReferee: "/register/referees/create",
  editReferee: "/register/referees",
  getPlayerDetails: "/v2/players-list?",
  getPlayerDetailsSubMember: "/v3/players-list?",
  getCsaPlayerDetails: "/csa/players-list?",
  getPlayerDetailByID: "/player-details/",
  setPlayer: "/register/player/create",
  editPlayer: "/register/player",
  getMemberDetails: "/member-listing-api?",
  getMemberDetailByID: "/member-details/",
  setMember: "/register/member/create",
  editMember: "/register/member",
  createTeam: "/register/team/create",
  updateTeam: "/register/team",
  getTeamList: "/teams-listing-api?",
  getCsaTeamList: "/csa-teams-listing-api?",
  teamDetails: "/team-details/",
  getPrimarySchoolDetails: "/primary-listing-api/",
  getPrimarySchoolDetailByID: "/primary-school-details/",
  setPrimarySchool: "/register/primary-school/create",
  editPrimarySchool: "/register/primary-school",
  getHighSchoolDetails: "/high-listing-api/",
  getHighSchoolDetailByID: "/high-school-details/",
  setHighSchool: "/register/high-school/create",
  editHighSchool: "/register/high-school",
  getClubDetails: "/club-listing-api/",
  getClubDetailByID: "/club-details/",
  setClub: "/register/club/create",
  editClub: "/register/club",
  getAncillaryMemberDetails: "/ancillary-listing-api/",
  getAncillaryMemberDetailByID: "/ancillary-details/",
  setAncillaryMember: "/register/ancillary/create",
  editAncillaryMember: "/register/ancillary",
  getNetDetails: "/nets-listing-api?",
  getNetDetailByID: "/nets-details/",
  setNet: "/register/net/create",
  editNet: "/register/net",
  getFieldDetails: "/fields-listing-api?",
  getFieldDetailByID: "/fields-details/",
  setField: "/register/fields/create",
  editField: "/register/fields",
  changePassword: "/user/",
  resetPasswordByMail: "/user/lost-password?_format=json",
  resetPassword: "/user/lost-password-reset?_format=json",
  resetUserName: "/user/lost-username?_format=json",
  getProfile: "/my-profile",
  setProfile: "/my-profile",

  getHubDetails: "/hub-rpc-listing-api",
  getHubDetailByID: "/hub-rpc-details/",
  setHub: "/register/hub/create",
  editHub: "/register/hub",
  getMiniCricketList: "/mini-cricket-listing-api",
  getMiniCricketById: "/mini-cricket-details/",

  getUserInfoById: "/user-info/",
  getMemberMasterData: "/get-member-names",
  getUserImage: "/profile-image/",
  editPMiniCricket: "/register/mini-cricket",
  getVenues: "/fields-listing-api/",
  getplayerStats: "/player-statistics",
  getteamStats: "/cms-teams-listing-api?",
  getPlayersList: "/players-list/",
  getCompetitionviceMatches: "/competitions/",
  getFieldsSubMember: "/api/venues",
  updateSubMemberMainField: "/api/venues/update-main-field/create",
  updateEmail:"/api/v1/username-email-update",
  deletePofileImage: "profile-image?uid=",

  ///////////////////////////////////////////////CMS-UMS????????????????????????????????
  getRegisteredMemberList: "/members",
  getCMSSubMemberList: "/cms-listing",
  getCMSTeamList: "/cms-teams",
  getCMSUmpireList: "/cms-umpire",
  getCMSScorerList: "/cms-scorer",
  getCMSFieldList: "/cms-fields",

  /////////////////////CMS//////////////////////////////////

  getCMSMasterList: base_URl_CMS + "/configs?type=competition_settings",
  getCMSList: base_URl_CMS + "/competitions",
  setCMS: base_URl_CMS + "/competitions",
  getCMSDetail: base_URl_CMS + "/competitions/",
  serachCMS: base_URl_CMS + "/competitions/search",
  setPool: base_URl_CMS + "/competitions/",
  getPool: base_URl_CMS + "/competitions/",
  setMatch: base_URl_CMS + "/competitions/",
  getAllMatches: base_URl_CMS + "/matches",
  getScore: base_URl_CMS + "/score/",
  getAllCompetitions: base_URl_CMS + "/competitions",
  getCompetitionById: base_URl_CMS + "/competitions/",
  getStats: base_URl_CMS + "/stats/",
  ExportReport: base_URl_CMS + "/stats/match/",
  getTournamentStats: base_URl_CMS + "/stats/competition/",
  validateHeadCoach: "/validate-head-coach/",
  validateTeam: "/validate-team-a/",
  clubAdministration: "/club_administrator/",
  getGraphs: "/api/mongo/data/",
  postSubMemberField: "/api/venues/move",
  deleteSubMemberField: "/api/venues/delete",
  postReport: base_URl_REPORTS + "/reports/export",
  getAllReports: base_URl_REPORTS + "/reports/export/all/",
  getQoo: base_URl_CMS + "/stats/match/",
  getStreamUrl: base_URl_CMS + "/matches/streaming/",
  getSubMemberVenues: "/api/sub_venues/",
  validateMainField: "/api/venues/update-main-field/",
  getLogos: "/member-logo",

  getSubMemberType: "/sub-member-type-user/",
  syncMatchReport:base_URl_CMS+"/score/tournament-changed",

  //---->  streaming servicess ----->
  redeemToken: base_Url_Streaming + "/external/redeem",
  validateProviderId: base_Url_Streaming + "/external/redeem/",
};
