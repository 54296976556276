import styles from "./ScoreCard.module.scss";
import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import nologo from "../../images/nologoclub.png";
import batIcon from "../../images/Bat.svg";
import ballIcon from "../../images/Ball.svg";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import {
  findLastObj,
  fixImage,
  fixSpecialChar,
  getShortenedName,
} from "utils/helpers";
import { Spin } from "antd";

function ScoreCard({ ...props }) {
  const { scoreData, onchangecomponent, propsData } = props;
  const navigate = useNavigate();
  const { addToast } = useToasts();

  const { matchId, scoreId, tName, banner } = propsData;

  const hasMultipleInns = scoreData?.numOvers === 0;
  const inningsData = scoreData?.innings;
  const inningsDataLoading = scoreData && !inningsData;

  const hasSuperOver = inningsData?.some(
    (inning) => inning.isSuperOver === true
  );

  const handleNavigateScoreCard = () => {
    onchangecomponent({
      // key: "2",
      // page: "matchinfo",
      scId: scoreData?.id || "",
      skip: true,
      // mId: matchId,
    });
    navigate(`${scoreData?.matchId}`, {
      state: {
        data: scoreData,
        matchId: scoreData.matchId,
        skip: true,
        matchId,
        scoreId,
        tName,
        banner,
        // mId: matchId,
      },
    });
  };
  //
  const homeTeamArr = inningsData?.filter(
    (team) => scoreData.homeTeamId === team.battingTeamId
  );
  const awayTeamArr = inningsData?.filter(
    (team) => scoreData.awayTeamId === team.battingTeamId
  );

  const battingTeamId = findLastObj(inningsData)?.battingTeamId;

  const currentBattTeamArr =
    battingTeamId === scoreData?.homeTeamId ? homeTeamArr : awayTeamArr;

  const currentBowlingTeamArr =
    battingTeamId === scoreData?.homeTeamId ? awayTeamArr : homeTeamArr;

  console.log(
    inningsData,
    hasSuperOver,
    currentBowlingTeamArr,
    currentBattTeamArr,
    "inns"
  );

  const battingArr = findLastObj(currentBattTeamArr);
  const battingArr1stInn =
    currentBattTeamArr?.length > 1 && currentBattTeamArr[0];

  const bowlingArr = findLastObj(currentBowlingTeamArr);
  const bowlingArr1stInn =
    currentBowlingTeamArr?.length > 1 && currentBowlingTeamArr[0];

  const bowlTeamTotal =
    Number(bowlingArr1stInn?.teamSummary?.score || 0) +
    Number(bowlingArr?.teamSummary?.score || 0);
  const batTeamTotal =
    Number(battingArr?.teamSummary?.score || 0) +
    Number(battingArr1stInn?.teamSummary?.score || 0);

  const runsGap =
    batTeamTotal > bowlTeamTotal
      ? batTeamTotal - bowlTeamTotal
      : bowlTeamTotal - batTeamTotal;
  /// const trailBy = bowlTeamTotal - batTeamTotal;

  console.log(scoreData, "status");

  const matchStatus =
    (runsGap === 0 && "Scores level") ||
    (bowlTeamTotal > batTeamTotal && " trail by " + runsGap + " runs") ||
    (bowlTeamTotal < batTeamTotal && " lead by " + runsGap + " runs");

  const team1Logo = scoreData?.teams?.find(
    (team) => team.teamId === battingArr?.battingTeamId
  )?.teamLogo;

  const team2Logo = scoreData?.teams?.find(
    (team) => team.teamId !== battingArr?.battingTeamId
  )?.teamLogo;

  return (
    <div
      className={styles.ScoreCardWrapper}
      // onClick={() => handleNavigate({ key: "2", tId: scoreData.id })} //key: "2", tId: scoreData?.id
      onClick={
        inningsData?.length === 0
          ? () =>
              addToast("Scorecard not avilable", {
                appearance: "warning",
              })
          : () => handleNavigateScoreCard()
      }
    >
      <div className="d-flex flex-column">
        <div className={styles.cardHeading}>{scoreData?.name}</div>
        <div className={styles.cardSubHeading}>
          {/* <span>Thu 24 February 19:00(local)</span> */}
          <span>
            {moment(scoreData?.matchDate, "YYYY-MM-DD").format("ddd DD MMMM")}
          </span>
          <span>
            {moment(scoreData?.matchTime, "HH:mm").format("HH:mm")} (CAT)
          </span>
          <span> {scoreData?.venue}</span>
        </div>
        {inningsDataLoading ? (
          <div className="w-100 py-5 my-5 d-flex align-items-center justify-content-center">
            <Spin size="medium" />
          </div>
        ) : inningsData.length === 0 ? (
          <p
            style={{
              fontSize: "13px",
              fontWeight: "bold",
              marginTop: "8px",
              color: "gray",
            }}
          >
            Failed to load score..
            <span role="img" aria-label="warn">
              {" "}
              ⚠️
            </span>
          </p>
        ) : (
          <div className={styles.cardScore}>
            <div className={styles.cardScoreItem}>
              <div className={styles.cardScoreLogo}>
                <img src={fixImage(team1Logo)} alt="" />
              </div>
              <div className={styles.cardScoreTeamWrapper}>
                <div className={styles.cardScoreTeam}>
                  <span>
                    {battingArr?.battingTeamName?.replace(/&#039;/g, "'")}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // color: "gray",
                  }}
                >
                  {hasMultipleInns && battingArr1stInn && (
                    <div className={styles.cardScoreTeamScore}>
                      <span
                        style={{
                          fontSize: "0.7rem",
                          fontWeight: "medium",
                          color: "gray",
                        }}
                      >
                        1st &nbsp;
                      </span>
                      <span
                        style={{
                          fontSize: "0.9rem",
                          fontWeight: "bold",
                          color: "gray",
                        }}
                      >
                        {battingArr1stInn?.teamSummary?.score ||
                          0 + "/" + battingArr1stInn?.teamSummary?.wickets ||
                          0}
                      </span>
                      <span
                        style={{
                          fontSize: "0.6rem",
                          alignSelf: "center",
                          marginTop: "7px",
                          marginLeft: "5px",
                          fontWeight: "bold",
                          color: "gray",
                        }}
                      >
                        ({battingArr1stInn?.teamSummary?.overs || 0}
                        &nbsp;ov)
                      </span>
                    </div>
                  )}

                  <div className={styles.cardScoreTeamScore}>
                    {hasMultipleInns && (
                      <span
                        style={{
                          fontSize: "0.7rem",
                          fontWeight: "medium",
                        }}
                      >
                        {battingArr1stInn ? "2nd" : "1st"}&nbsp;
                      </span>
                    )}

                    <span
                      style={{
                        fontSize: "0.9rem",
                        fontWeight: "bold",
                      }}
                    >
                      {battingArr?.teamSummary?.score || 0}/
                      {battingArr?.teamSummary?.wickets || 0}
                    </span>
                    <span
                      style={{
                        fontSize: "0.6rem",
                        alignSelf: "center",
                        marginTop: "7px",
                        marginLeft: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      ({battingArr?.teamSummary?.overs || 0}
                      &nbsp;ov)
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.cardScorePlayIcon}>
                <img src={batIcon} alt="Batting" />
              </div>
            </div>
            <div className={styles.cardPlayerScore}>
              <div className={styles.cardScorePlayer}>
                <span>
                  {getShortenedName(
                    fixSpecialChar(
                      battingArr?.summaryBatting?.find(
                        (player) =>
                          player?.battingPlayerId ===
                          battingArr?.battingPlayer1Id
                      )?.batsman?.name || ""
                    ),
                    20
                  ) + "*"}
                </span>
                <span>
                  {battingArr?.summaryBatting?.find(
                    (player) =>
                      player?.battingPlayerId === battingArr?.battingPlayer1Id
                  )?.runs
                    ? battingArr?.summaryBatting?.find(
                        (player) =>
                          player?.battingPlayerId ===
                          battingArr?.battingPlayer1Id
                      )?.runs +
                      "(" +
                      battingArr?.summaryBatting?.find(
                        (player) =>
                          player?.battingPlayerId ===
                          battingArr?.battingPlayer1Id
                      )?.balls +
                      ")"
                    : ""}
                </span>
              </div>
              <div className={styles.cardScorePlayer}>
                <span>
                  {getShortenedName(
                    fixSpecialChar(
                      battingArr?.summaryBatting?.find(
                        (player) =>
                          player?.battingPlayerId ===
                          battingArr?.battingPlayer2Id
                      )?.batsman?.name
                    ),
                    20
                  ) || ""}
                </span>
                <span>
                  {battingArr?.summaryBatting?.find(
                    (player) =>
                      player?.battingPlayerId === battingArr?.battingPlayer2Id
                  )?.runs
                    ? battingArr?.summaryBatting?.find(
                        (player) =>
                          player?.battingPlayerId ===
                          battingArr?.battingPlayer2Id
                      )?.runs +
                      "(" +
                      battingArr?.summaryBatting?.find(
                        (player) =>
                          player?.battingPlayerId ===
                          battingArr?.battingPlayer2Id
                      )?.balls +
                      ")"
                    : ""}
                </span>
              </div>
            </div>
            <div className={styles.cardScoreItem}>
              <div className={styles.cardScoreLogo}>
                <img src={fixImage(team2Logo)} alt="" />
              </div>

              <div className={styles.cardScoreTeamWrapper}>
                <div className={styles.cardScoreTeam}>
                  <span>
                    {fixSpecialChar(battingArr?.bowlingTeamName) || ""}
                  </span>
                </div>

                <div style={{ display: "flex", flexDirection: "column" }}>
                  {hasMultipleInns && bowlingArr1stInn && (
                    <div className={styles.cardScoreTeamScore}>
                      <span
                        style={{
                          fontSize: "0.9rem",
                          fontWeight: "bold",
                          color: "gray",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "0.7rem",
                            fontWeight: "medium",
                          }}
                        >
                          1st &nbsp;
                        </span>
                        {bowlingArr1stInn?.teamSummary?.score || 0}/
                        {bowlingArr1stInn?.teamSummary?.wickets || 0}
                      </span>
                      <span
                        style={{
                          fontSize: "0.6rem",
                          alignSelf: "center",
                          marginTop: "7px",
                          marginLeft: "5px",
                          fontWeight: "bold",
                          color: "gray",
                        }}
                      >
                        ({bowlingArr1stInn?.teamSummary?.overs || 0})
                      </span>
                    </div>
                  )}

                  <div className={styles.cardScoreTeamScore}>
                    {hasMultipleInns && (
                      <span
                        style={{
                          fontSize: "0.7rem",
                          fontWeight: "medium",
                        }}
                      >
                        {bowlingArr1stInn ? "2nd" : "1st"} &nbsp;
                      </span>
                    )}

                    <span style={{ fontSize: "0.9rem", fontWeight: "bold" }}>
                      {bowlingArr?.teamSummary?.score || 0}/
                      {bowlingArr?.teamSummary?.wickets || 0}
                    </span>
                    <span
                      style={{
                        fontSize: "0.6rem",
                        alignSelf: "center",
                        marginTop: "7px",
                        marginLeft: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      ({bowlingArr?.teamSummary?.overs || 0}
                      &nbsp;ov)
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.cardScorePlayIcon}>
                <img src={ballIcon} alt="Balling" />
              </div>
            </div>
            <div className={styles.cardPlayerScore}>
              <div className={styles.cardScorePlayer}>
                <span>
                  {getShortenedName(
                    fixSpecialChar(
                      battingArr?.summaryBowling?.find(
                        (player) =>
                          player?.bowlingPlayerId ===
                          battingArr?.bowlingPlayerId
                      )?.bowler?.name || ""
                    ),
                    20
                  )}
                </span>
                <span>
                  {battingArr?.summaryBowling?.find(
                    (player) =>
                      player?.bowlingPlayerId === battingArr?.bowlingPlayerId
                  )?.wickets
                    ? battingArr?.summaryBowling?.find(
                        (player) =>
                          player?.bowlingPlayerId ===
                          battingArr?.bowlingPlayerId
                      )?.wickets +
                      "/" +
                      battingArr?.summaryBowling?.find(
                        (player) =>
                          player?.bowlingPlayerId ===
                          battingArr?.bowlingPlayerId
                      )?.runs +
                      "(" +
                      battingArr?.summaryBowling?.find(
                        (player) =>
                          player?.bowlingPlayerId ===
                          battingArr?.bowlingPlayerId
                      )?.overs +
                      ")"
                    : ""}
                </span>
              </div>
              <div className={styles.cardScorePlayer}>
                <span>
                  {getShortenedName(
                    fixSpecialChar(
                      battingArr?.summaryBowling?.find(
                        (player) =>
                          player?.bowlingPlayerId ===
                          battingArr?.previousBowlerId
                      )?.bowler?.name || ""
                    ),
                    20
                  )}
                </span>
                <span>
                  {battingArr?.summaryBowling?.find(
                    (player) =>
                      player?.bowlingPlayerId === battingArr?.previousBowlerId
                  )?.wickets
                    ? battingArr?.summaryBowling?.find(
                        (player) =>
                          player?.bowlingPlayerId ===
                          battingArr?.previousBowlerId
                      )?.wickets +
                      "/" +
                      battingArr?.summaryBowling?.find(
                        (player) =>
                          player?.bowlingPlayerId ===
                          battingArr?.previousBowlerId
                      )?.runs +
                      "(" +
                      battingArr?.summaryBowling?.find(
                        (player) =>
                          player?.bowlingPlayerId ===
                          battingArr?.previousBowlerId
                      )?.overs +
                      ")"
                    : ""}
                </span>
              </div>
            </div>
            <span
              style={{
                fontWeight: "bold",
                fontSize: "0.7rem",
                color: "gray",
                textAlign: "center",
              }}
            >
              {hasMultipleInns && runsGap === 0
                ? matchStatus
                : hasMultipleInns &&
                  `${fixSpecialChar(
                    battingArr?.battingTeamName
                  )}  ${matchStatus}`}
            </span>
          </div>
        )}

        <div className={styles.cardFooter}></div>
      </div>
    </div>
  );
}

export default ScoreCard;
